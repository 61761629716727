import React,{useState} from 'react'
import{EventsArray} from '../comp/Eventsarray';
import "../css/Eventstyle.css";

 const Events =()=> {
  const [monthIndex,setMonthIndex]= useState(1);
  return (
  <div class="p-0 ">
  <div class=" boxabout p-2  d-flex align-items-center justify-content-center ">
    <div> 
    <p class=" fs-1  text-white  fw-bold text-center  "> Events 2023-24</p></div>
    </div>
    <div class="row p-0 m-0">
    <div class="col-lg-3 col-md-3 col-sm-12 p-1">
    <div class=" second shadow-lg  p-3  text-center fs-4 fw-bold ">
      <p>Months</p>
      </div>
      {EventsArray.map((item ,index)=>(
      <>
       <div  class="first shadow-lg m-2  rounded  d-flex align-items-center justify-content-center"  onClick={()=>setMonthIndex(index) } >
       <p class="text-center fs-5 "> {item.month} </p>
        </div>
      </>
      ))
      }
    </div>
    <div class="col-lg-9 col-md-9 col-sm-12 p-1 ">
      <div class=" second p-3 mb-2 shadow-lg text-center fs-4 fw-bold  ">
       <p>  {EventsArray[monthIndex].month}  </p>
      </div>
    {EventsArray[monthIndex].events.map((item ,index)=>(
      <div  class=" p-2 shadow p-2 mb-2 bg-white rounded "  onPress={()=>setMonthIndex(index) }>
        <div class="row  m-0">
        <div class="col-lg-2 col-md-2 col-sm-12 ">
          <p class="text-center fs-6"> {item.date}</p>
          <p class="text-center fs-6"> {item.day}</p>
         </div>
        <div class="col-lg-10 col-md-10 col-sm-12 ">
          <p>{item.name}</p>
          <p>{item.content.map((contents,index)=>(
          <>
          <p class="content "key={index}>{contents}</p>
          </>))}</p>
        </div>
      </div>      
      </div>
      ))
      }
    </div>
    </div>
  </div>   
  )
}
export default Events;
