import React from 'react'
import { Imagearray } from '../comp/Imagearray';

 function Gallery() {
  return (
    <div class="header p-0">
    <div class=" boxabout p-2  d-flex align-items-center justify-content-center ">
    <div> 
    <p class=" fs-1  text-white  fw-bold text-center  ">Gallery</p></div>
    </div>
    <div class=" row g-2 p-0 m-0 ">
    {Imagearray.map((item ,index)=>(
      <img src={item}  key={index }class=" col-lg-3 col-md-4 col-sm-2  p-1 border border-3 border-black  img-fluid" alt="sorry no image" 
        // height={'250'}
        //  width={'340'}
         height={'50%'}
         width={'100%'}
        />
         ))}
    </div>
    </div>
  )
}
export default Gallery;
