export const ForaneArray =[
  {"forane":"CHELAKKARA",
    "unit": [
      "CHELAKKARA",
      "CHERUTHURUTHY",
      "ELANAD",
      "KILLIMANGALAM",
      "MANALADY",
      "MAYANNUR",
      "MULLURKARA",
      "PANGARAPILLY",
      "PARAKKAD",
      "PAZHAYANNUR",
      "THIRUVILWAMALA"
    ]},
    { "forane":"ERUMAPETTY",
      "unit": [
        "ATTATHARA",
        "CHOWANNUR",
        "ERUMAPETTY",
        "EYYAL",
        "KADANGODE",
        "MANGAD",
        "MARATHAMKODE",
        "PAREMPADAM",
        "PATHIYARAM",
        "PATHRAMANGALAM",
        "PUTHURUTHY",
        "VELLARAKKAD"
      ]
    },
    {"forane":"KANDASSANKADAVU", 
       "unit": [
      "ARIMPUR",
      "ENAMAKKAL",
      "ENGANDIYUR",
      "ERAVU",
      "KANDASSANKADAVU",
      "KANJANI",
      "KARAMUCK",
      "KARAMUCK NORTH",
      "KIZHAKKUMPURAM",
      "MANAKODY",
      "MANALUR EAST",
      "MANALUR WEST",
      "MULLASSERY",
      "NORTH PUDUKAD",
      "THOYAKKAVU",
      "VADANAPPILLY",
      "VELUTHUR",
      "THALIKULAM"
    ]},
    {"forane":"KOTTEKAD",
  "unit":[
    "AVANUR",
    "CHOOLISSERY",
    "KOLENGATTUKARA",
    "KOTTEKAD",
    "KUTTUR",
    "MUNDUR",
    "PERAMANGALAM",
    "POTTORE",
    "VARADIAM",
    "VELAPPAYA",
    "VIYYUR"
    ]},
    {"forane":"MATTOM",
"unit":[
  "ALOOR",
  "ALOOR EAST",
  "CHITTATTUKARA",
  "CHOONDAL",
  "ELAVALLY",
  "KANDANASSERY",
  "KOONAMMOOCHY",
  "KUNNAMKULAM",
  "MATTOM",
  "NAMBAZHIKAD",
  "PAZHUNNANA",
  "PUDUSSERY",
  "VAKA"
    ]},
    {"forane":"OLLUR",
      "unit": [
        "AMMADAM",
        "ANCHERY",
        "AVINISSERY",
        "CHIYYARAM",
        "GALILEE",
        "KODANNUR",
        "KONIKKARA",
        "MARATHAKKARA",
        "OLLUR",
        "PADAVARAD",
        "PALAKKAL",
        "PALLISSERY",
        "OLLUR MARYMATHA",
        "PERINCHERY",
        "POOTHARAKKAL",
        "PULAKKATTUKARA",
        "THAIKKATTUSSERY",
        "THALORE",
        "VALLACHIRA",
        "VENGINISSERY"
      ]
    },
    {"forane":"PALAYUR",
    "unit": [
      "ANJOOR",
      "ARTHAT",
      "ATTUPURAM",
      "BRAHMAKULAM",
      "CHEMMANNUR",
      "GURUVAYUR",
      "KAKKASSSERY",
      "KAVEED",
      "KOTTAPADY",
      "ORUMANAYUR",
      "PALAYUR",
      "PAVARATTY",
      "PERAKAM",
      "PERINGAD",
      "PONNANI",
      "VYLATHUR"
    ]},
    {"forane":"PARAPPUR",
    "unit": [
      "ADAT",
      "AMALA NAGAR",
      "AMBAKAD",
      "CHITTILAPPILLY",
      "EDAKKALATHUR",
      "PARAPPUR",
      "PERUVALLUR",
      "PONNORE",
      "PURANATTUKARA",
      "THOLOOR"
    ]},
    {"foraner":"PATTIKAD",
    "unit": [
      "CHERUMKUZHY",
      "CHIRAKKAKODE",
      "CHUVANNAMANNU",
      "KANNARA",
      "KOOTTALA",
      "MANJAKKUNNU",
      "MULAYAM",
      "PATTIKAD",
      "PEECHI",
      "VANIAMPARA",
      "VEENDASSERY",
      "VELLAKARITHADAM"
    ]}, 
    {"forane":"PAZHUVIL",
    "unit": [
      "ANTHIKAD",
      "CHAZHUR",
      "CHERPU",
      "CHEVOOR",
      "CHIRAKKAL",
      "KIZHUPPILLIKKARA",
      "MUTTICHUR",
      "NATTIKA",
      "PAZHUVIL",
      "PERINGOTTUKARA",
      "POOCHINNIPADAM",
      "PORATH"
    ]
    },  
    {"forane":"PUDUKAD",
     "unit":["PUDUKAD",
     "CHENGALUR",
     "CHITTISSERY",
     "KALLUR EAST",
     "KALLUR WEST",
     "MANNAMPETTA",
     "NANDIPULAM",
     "NANDIPULAM NORTH",
     "NENMANIKKARA",
     "PALAZHY",
     "PALLIKKUNNU",
     "PUDUKAD",
     "SNEHAPURAM",
     "THORAVU",
     "VARAKKARA",
     "VARAKKARA SOUTH",
     "VARANDARAPPILLY",
     "VELLANIKODE",
     "VELUPADAM",
     "VENDORE",]},
     {"forane":"PUTHUR",
     "unit":["BARATHA",
     "ERAVIMANGALAM",
     "KOZHUKKULLY",
     "MANNAMANGALAM",
     "MAROTTICHAL",
     "PONNUKKARA",
     "PUTHUR",
     "THRIKKUR",
     "VALAKKAVU",
     "VETTUKAD",]},
     {"forane":"THRISSUR LOURDES",
     "unit":["CHEMBOOKAVU",
     "DON BOSCO",
     "KUNDUKAD",
     "MADAKKATHARA",
     "MADONA NAGAR",
     "MANNUTHY",
     "MARIAPURAM",
     "MUKKATTUKARA",
     "MULLAKKARA",
     "NADATHARA",
     "NELLANKARA",
     "NELLIKKUNNU",
     "OLLUKKARA",
     "PARAVATTANI",
     "PONGANAMKAD",
     "PULLAMKANDAM",
     "PUNKUNNAM",
     "THRISSUR LOURDES",
     "VIJAYAPURAM",
     "VILLADAM",]},
     {"forane":"THRISSUR DOLOURS",
     "unit":["ARANATTUKARA",
     "AYYANTHOLE",
     "CHETTUPUZHA",
     "ELTHURUTH",
     "KANNAMKULANGARA",
     "KOKKALA",
     "KURIACHIRA",
     "NEDUPUZHA",
     "NEHRU NAGAR",
     "NIRMALAPURAM",
     "OLARIKKARA",
     "PANAMUCK",
     "POOTHOLE",
     "PULLAZHI",
     "SEVANALAYAM",
     "THRISSUR DOLOURS",
     "VADUKARA",
     "WEST FORT",]},
     {"forane":"VELUR ",
     "unit":["ARAMPILLY",
     "CHIRANELLUR",
     "ERANELLUR",
     "KIRALUR",
     "KURUMAL",
     "KUTTAMKULAM",
     "MUNDATHIKODE",
     "PARANNUR",
     "PAZHAYANGADI",
     "THALAKKOTTUKARA",
     "THANDILAM",
     "THANGALUR",
     "THANGALUR N.",
     "THAYYUR",
     "VELLATTANJOOR",
     "VELUR",]},
     {"forane":"WADAKKANCHERY",
     "unit":["ATHANY",
     "KARUMATHRA",
     "KOLAZHI",
     "KUMBALANGAD",
     "KUNDANNUR",
     "MACHAD",
     "POOMALA",
     "SARITHAPURAM",
     "THIROOR",
     "THIRUTHIPARAMBU",
     "VARAVOOR",
     "WADAKKANCHERY"]},
];
  