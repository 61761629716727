import React from 'react'
import'../css/footer.css'
import Fab from '../custom components/Fab';
 function Footer() {
  return (
    <div class="px-3 footer " id="footer"> 
    <div>
      <div class="row p-0 m-0">
      <div class=" col-md-12 col-lg-6 col-sm-12 d-flex justify-content-start align-items-center">
    <img src="/assets/logos/cathecathecial/logo-2.png" alt="  university" width="200" height="200" class=" m-3  "/>
      </div>
    

      </div>
      {/* <div class="d-flex justify-content-start align-items-center">
    <img src="/assets/logos/cathecathecial/logo-2.png" alt="  university" width="200" height="200" class=" m-3  "/>
      </div> */}
    <div class=" row my-5 mx-4" >
  <div class="d-flex justify-content-start col-md-4 col-sm-12 col-lg-3  p-2 ">
<div class="">
<h3 class="text-white  "> Archdiocesan Catechetical Centre </h3>
  <p class="text-white fs-6 " >The Mission of the Archdiocesan Catechetical Centre is to introduce and to conduct ongoing training programmes that help the faithful to deepen and build up their faith in the person of Jesus Christ.
   </p>
  <p class="text-white fs-6"> <i class="  fa-solid fa-location-dot text-white "></i>
  DBCLC ,Archdiocesan Catechetical Centre , East Fort, Thrissur
    </p>   
    {/* <p class="text-white fs-6"> <i class="  fa-solid fa-location-dot text-white "></i>
    Civil Service Campus, Avinissery, Ollur, Thrissur
    </p>  */}
    {/* <p class="text-white fs-6"> <i class="  fa-solid fa-clock text-white "></i>
    Monday - Saturday at 9 AM - 6 PM
    </p>  */}
     
</div>
  </div>
  <div class=" d-flex justify-content-start col-md-4 col-sm-12  col-lg-2 p-2 ">
  <div class="mx-3">
  <h3 class="text-white "> Page links..</h3>
  {/* <div><a class="  fs-6 text-white text-decoration-none " href="#Services"> ..Courses </a></div> */}
  {/* <div><a class=" fs-6 text-white text-decoration-none" href="#team"> ..Team </a></div> */}
  <div class="my-2 "><a class="  fs-6 text-white text-decoration-none " href="#"> ..Home</a></div>
  <div class="my-2 "><a class="  fs-6 text-white text-decoration-none " href="#"> ..History </a></div>
  <div class="my-2 "><a class="  fs-6 text-white text-decoration-none " href="#"> ..School Cathechism</a> </div>
  <div class="my-2 "><a class="  fs-6 text-white text-decoration-none " href="#"> ..Gallery </a></div>
  <div class="my-2 "><a class="  fs-6 text-white text-decoration-none " href="#"> ..News & Events </a></div>
  <div class="my-2 "><a class="  fs-6 text-white text-decoration-none " href="#"> ..Question Bank</a> </div>
  {/* <div><a class="  fs-6 text-white text-decoration-none " href="#"> ..Home </a></div>
  <div><a class="  fs-6 text-white text-decoration-none " href="#"> ..About </a></div> */}
  <div class="my-2 "><a class="  fs-6 text-white text-decoration-none " href="#"> ..Contact</a> </div>
</div>

  </div>
  <div class="d-flex flex-column justify-content-start  col-md-4  col-sm-12 col-lg-3 p-2 ">
  <div class="">
  <h3 class="text-white "> Contacts</h3>
  <p class="text-white fs-6"> <i class="  fa-solid fa-location-dot text-white "></i>
  DBCLC ,Archdiocesan Catechetical Centre, East Fort, Thrissur
  </p>
  {/* <p class="text-white fs-6"> <i class="  fa-solid fa-location-dot text-white "></i>
  Civil Service Campus, Avinissery, Ollur, Thrissur
  </p> */}
  <p class="text-white fs-6"> <i class="  fa-solid fa-calendar-days text-white "></i> Monday - Saturday at 9 AM - 6 PM </p>
  {/* <p class="text-white fs-6">  <i class="  fa-solid fa-envelope text-white "></i> excelacademytcr@gmail.com , excelresidentcampus@gmail.com </p>  */}
  <p class="text-white fs-6">  <i class="  fa-solid fa-phone text-white "></i> +91 9188131321 ,+91  9188131311 ,</p>
  <p class="text-white fs-6">  <i class=" fa-brands fa-whatsapp text-white "></i> +91 8281764445</p>   
</div> 
<div class=" d-flex align-items-center justify-content-start " > 
    <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-facebook  fa-2x"></i>  </a>
    {/* <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-twitter  fa-2x"></i>  </a> */}
    {/* <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-instagram  fa-2x"></i>  </a> */}
    {/* <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-linkedin fa-2x"></i>  </a> */}
    <a class=" m-2 text-decoration-none px-2" href="https://www.youtube.com/@kingdomofgod884"> <i class="  fa-brands fa-youtube fa-2x"></i>  </a>
    {/* <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-pinterest   fa-2x"></i>  </a> */}
    <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-whatsapp  fa-2x"></i>  </a>
    {/* <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-telegram fa-2x"></i>  </a> */}
   </div>
  </div>
 
</div>
<div>
<Fab/>
</div>
<div class=" row bg-black p-0 ">
<div class=" col-md-12 col-sm-12 col-lg-12 d-flex align-items-center justify-content-center py-3 ">     
      <div  class=" fs-6   text-white text-center">© Copyright 2023  Designed By Apex Data Technologies All Rights Reserved . </div>
    </div>
   
 
  </div>
      </div>       
    </div>
  )
}
export default Footer;