import React from 'react'

export default function ForaneUnitcard(props) {
  return (
    <div class="card  border border-0  bg-white rounded-4 shadow-lg m-2">
    {/* <img src="/assets/images/job-3.png" class="img-reg m-2 "  height=" 80" width="80"alt="test image" /> */}
    <div class=" card-body ">
      <h4 class="card-title text-primary "> {props.unit}</h4>
      <p class="card-text  fs-6  text-black "> We help you realize your dream of securing a government job. We train you for PSC, SSC, KTET and bank exams</p>     
      {/* <a class="btn btn-outline-primary btn-md text-decoration-none  "href="/">More Details</a>  */}
    </div>
  </div> 
  )
}
