export const EventsArray =[

    { 
        month:'May 2023',
        events:[
        {
        date:'14 Sunday',
        name:'സ്റ്റാഫ് മീറ്റിങ്ങ് - ഇടവകതലത്തിൽ',
        content:['(Selection of Principal, Vice-Principal, Software in charge, Secretary, Class Teachers fixing, Extra Teaching Staffs and 3 Animators for Spiritual-Talent and Social Ministries)'],
        },
        {
            date:'21 Sunday',
            name:'• Principal Conference 9.30 am DBCLC',
            content:["♦ Angelos 2023 - Solemn Holy Communion Children's Meet-01.30 pm (Children's Ministry)"],
        },
        {   date:'25 Thursday',
            name:'',
           content:["* വിശ്വാസപരിശീലകരുടെ വാർഷിക ധ്യാനം റുഹാഗ്നി 25, 26, 27 തിയതികളിൽ കാലത്ത് 10 മുതൽ വൈകീട്ട് 4.00 വരെ (3 Days) നയിക്കുന്നത് ബഹു. സേവിയർ ഖാൻ വട്ടായിൽ അച്ചൻ"],
        },
        {
            date:'27 Saturday',
            name:'അതിരൂപതാതല വിശ്വാസപരിശീലനം ഉദ്ഘാടനം: മാർ ആൻഡ്രൂസ് താഴത്ത്',
            content:["* വിശ്വാസപരിശീലനത്തിനായുള്ള മദ്ധ്യസ്ഥ ആരാധന DBCLC യിൽ - Night Vigil (എല്ലാ വെള്ളിയാഴ്ച്ചകളിലും)"],
        },
        {
            date:'28 Sunday',
            name:'പന്തക്കുസ്താ തിരുനാൾ  എഴുത്തിനിരുത്തൽ ഇടവകതലത്തിൽ',
            content:["വിശ്വാസപരിശീലകരുടെ അർദ്ധദിന സെമിനാർ ഫൊറോനതലം - 1pm to 5 pm"],
        }
        

    ],   
},
{ 
    month:'June 2023',
    events:[
    {
    date:'03 Saturday',
    name:'വിശ്വാസവർഷാരംഭത്തോടനുബന്ധിച്ചുള്ള ആരാധന',
    content:['Staff Fixation സംബന്ധിച്ച കാര്യങ്ങൾ, തെരഞ്ഞെടുക്കപ്പെട്ട ആനി മേറ്റേഴ്സിന്റെ പേരുവിവരങ്ങൾ എന്നിവ സോഫ്റ്റ്വെയറിൽ Upload ചെയ്യുന്നു'],
    },
    {
        date:'04 Sunday',
        name:'വിശ്വാസപരിശീലനം (1)',
        content:["• ഇടവകതലം - പ്രവേശനോത്സവം, വർഷാരംഭ പ്രാർത്ഥന, അധ്യാപക പ്രതിജ്ഞ, കുട്ടികളുടെ പ്രതിജ്ഞ"],
    },
    {   date:'10 Saturday',
        name:'ആദരം',
       content:["SSLC, +2 പരീക്ഷയിൽ ഫുൾ A+ നേടിയ വിദ്യാർത്ഥികൾക്കുള്ള സ്വീകരണം. ഡിബിസി എൽസി യിൽ (എക്സൽ അക്കാദമി)"],
    },
    {
        date:'11 Sunday',
        name:'',
        content:["Sunday വിശ്വാസപരിശീലനം (2)"],
    },
    {
        date:'12 Monday',
        name:'',
        content:["എക്സൽ അക്കാദമിയിൽ ക്ലാസ്സുകൾ ആരംഭിക്കുന്നു. (സിവിൽ സർവ്വീസ്) എക്സൽ അക്കാദമി"],
    },
    {
        date:'17 Saturday',
        name:'',
        content:[],
    },
    {
        date:'18 Sunday',
        name:'വിശ്വാസപരിശീലനം (3)',
        content:["CHRISFOEM II: Selection"],
    },
    {
        date:'24 Monday',
        name:'',
        content:["• New Academic Year Meeting-Promoters, Animators, Par- ents & Leaders Meet 1.30 pm to 4.00 pm DBCLC (ചിൽഡ്രൻസ് മിനിസ്ട്രി)"],
    },
    {
        date:'25 Sunday',
        name:'വിശ്വാസപരിശീലനം (4)',
        content:["Animators Course - Talent, Spiritual & Social Ministries 9.30 am - DBCLC","Unit Visitors Meeting: 2pm-5pm","അവസാന തിയതി: കുട്ടികളുടെ അഡ്മിഷൻ സംബന്ധ മായ കാര്യങ്ങളുടെ പൂർത്തീകരണം, അധ്യാപകരുടെ സർവ്വീസ് സംബന്ധമായ കാര്യങ്ങൾ, യൂണിറ്റ് വിസിറ്റേഴ്സ് എന്നിവ സോഫ്റ്റ് വെയറിൽ ചേർക്കേണ്ട അവസാന തീയതി"],
    },
    

],   
},
{ 
    month:'July 2023',
    events:[
    {
    date:'02 Sunday',
    name:'വിശ്വാസപരിശീലനം (5)',
    content:["Std. X, XI, XII, ACC ക്ലാസുകളിലെ വിശ്വാസപരിശീലകരുടെ ഏകദിന സെമിനാർ. (9:30 AM - 4:30 PM; DBCLC)"],
    },
    {
        date:'03 Monday',
        name:'',
        content:["ദുക്റാനത്തിരുനാൾ"],
    },
    {   date:'07-09 Friday-Sunday',
        name:'',
       content:["◆ CHRISFORM II - BATCH (01)"],
    },
    {
        date:'09 Sunday',
        name:'',
        content:["വിശ്വാസപരിശീലനം (6)"],
    },
    {
        date:'14 -16 Friday-Sunday',
        name:'',
        content:["♦ CHRISFORM I - BATCH (01)"],
    },
    {
        date:'16 Sunday',
        name:'വിശ്വാസപരിശീലനം (7)',
        content:["• Std. VI, VII, VIII, IX ക്ലാസുകളിലെ വിശ്വാസപരിശീലകരുടെ ഏകദിന സെമിനാർ. (9:30 AM - 4:30 PM; DBCLC) "],
    },
        {
        date:'17 Monday',
        name:'',
        content:["പ്രസംഗമത്സരം, ഇന്നവേറ്റീവ് ഐഡിയ മീറ്റ് (+1, +2, എസിസി വിദ്യാർത്ഥികൾക്ക്)(ലേ ലീഡർഷിപ്പ് ഇൻസ്റ്റിറ്റ്യൂട്ട്)"],
    },
    {
        date:'23 Sunday',
        name:'Sunday വിശ്വാസപരിശീലനം (8)',
        content:["Std. II, III, IV V ക്ലാസുകളിലെ വിശ്വാസപരിശീലകരുടെ ഏക Blm analmod. (9:30 AM - 4:30 PM; DBCLC)"],
    },
    {
        date:' 28 Friday ',
        name:'സാഹിത്യ മത്സരങ്ങൾ ',
        content:["(+1, +2, എസിസി വിദ്യാർത്ഥികൾക്ക്) (ലേ ലീഡർഷിപ്പ് ഇൻസ്റ്റിറ്റ്യൂട്ട്)"],
    },
    {
        date:'30 Sunday',
        name:'വിശ്വാസപരിശീലനം (9) ',
        content:["• Nursery, Std. I, ക്ലാസ്സ് ചാർജ് ഇല്ലാത്ത മറ്റ് അധ്യാപകർ, മറ്റ് ക്ലാസ്സ് സെമിനാറുകളിൽ പങ്കെടുക്കാൻ സാധിക്കാത്തവർ വിശ്വാസപരിശീലകരുടെ ഏകദിന സെമിനാർ. (9:30 AM - 4:30 PM; DBCLC)",
        "TACC വിദ്യാർത്ഥികളുടെ ഫോട്ടോ സഹിതമുള്ള യുണിവേ ഴ്സിറ്റി രജിസ്ട്രേഷൻ ഫോം (രജി. ഫീസ് 100 രൂപ ഉൾപ്പെടെ) വിശ്വാസപരിശീലന കേന്ദ്രത്തിൽ സമർപ്പിക്കേണ്ട തിയ്യതികൾ ആഗസ്റ്റ് 01 മുതൽ 21 വരെ."],
    }

],   
},
{ 
    month:'August 2023',
    events:[
    {
    date:'01 Tuesday',
    name:'Starting ACC University Registration Form Submission',
    content:[""],
    },
    {
        date:'04-06 Friday-Sunday',
        name:'♦ CHRISFORMI BATCH (02)',
        content:[],
    },
    {   date:'06 Sunday',
        name:'വിശ്വാസപരിശീലനം (10)',
       content:["♦ Leaders Meet 10 am (XI, XII, ACC Class Leaders)","♦ UNIT VISIT- 1"],
    },
    {
        date:'11-13 Friday-Sunday',
        name:'♦ CHRISFORM II BATCH (02)',
        content:[],
    },
    {
        date:'12 Saturday',
        name:'',
        content:["സാഹിത്യമത്സരങ്ങൾ - അതിരൂപതാതലം  DBCLC (ചിൽഡ്രൻസ് മിനിസ്ട്രി)"],
    },
    {
        date:'13 Sunday',
        name:'വിശ്വാസപരിശീലനം (11)',
        content:["♦ UNIT VISIT- 2"," CHRISSFORM III & FRESHERS COURSE (GATHER- ING 01) 9 am to 5 pm"],
    },
        {
        date:'15 Tuesday',
        name:'സ്വാതന്ത്യദിനം, സ്വർഗാരോപണതിരുനാൾ',
        content:["◆ ACC Convocation Day (10 am) ","Archdiocesan Catechetical Merit Day - (2 pm-5 pm)"],
    },
    {
        date:'17 Thursday',
        name:"",
        content:[],
    },
    {
        date:' 18-20 Friday - Sunday ',
        name:'◆ CHRISFORMI BATCH (03) ',
        content:["(+1, +2, എസിസി വിദ്യാർത്ഥികൾക്ക്) (ലേ ലീഡർഷിപ്പ് ഇൻസ്റ്റിറ്റ്യൂട്ട്)"],
    },
    {
        date:'20 Sunday',
        name:'വിശ്വാസപരിശീലനം (12) ',
        content:["◆ UNIT VISIT- 3"],
    },
    {
        date:'21 Monday',
        name:'',
        content:["ACC വിദ്യാർത്ഥികളുടെ ഫോട്ടോ സഹിതമുള്ള യൂണിവേ സിറ്റി രജിസ്ട്രേഷൻ ഫോം (രജി. ഫീസ് 100 രൂപ) - അവ സാന തിയതി"],
    },
    {
        date:'26-28 Saturday -Monday',
        name:' Teachers Retreat DBCLC ',
        content:[],
    },
    {
        date:'26 Saturday',
        name:'  Bible Poocolours 2023 ',
        content:["Venue: St. Joseph’s Model Public School, Kuriachira (ചിൽഡ്രൻസ് മിനിസ്ട്രി)"],
    },
    {
        date:'27 Sunday',
        name:' വിശ്വാസപരിശീലനം (13) ',
        content:["◆ UNIT VISIT- 4"],
    },
    

],   


},
{ 
    month:'September 2023',
    events:[
    {
    date:'01-03 Friday -Sunday',
    name:' CHRISFORM I - BATCH (04)n',
    content:[""],
    },
    {
        date:'03 Sundayy',
        name:'വിശ്വാസപരിശീലനം (14)',
        content:["♦ UNIT VISIT- "],
    },
    {   date:'06 Wednesday',
        name:'വിശ്വാസപരിശീലനം (10)',
       content:["* വർക്ക്ഷോപ്പ് (ലേ ലീഡർഷിപ്പ് ഇൻസ്റ്റിറ്റ്യൂട്ട്)1"],
    },
    {
        date:'08-10 Friday-Sunday',
        name:'♦ CHRISFORM II BATCH (03)',
        content:[],
    },
    {
        date:'09 Saturda',
        name:' Animators’ Meeting - DBCLC -1.30 pm to 4pm (ചിൽഡ്രൻസ് മിനിസ്ട്രി)',
        content:["സാഹിത്യമത്സരങ്ങൾ - അതിരൂപതാതലം  DBCLC (ചിൽഡ്രൻസ് മിനിസ്ട്രി)"],
    },
    {
        date:'10 Sunday',
        name:'വിശ്വാസപരിശീലനം (15)',
        content:["♦ CHRISFORM III - FRESHERS COURSE (GATHERING 02) 9 am to 5 pm","♦ UNIT VISIT- 6",]
    },
    {
        date:'15-17 Friday-Sunday',
        name:'♦ CHRISFORM I - BATCH (05)',
        content:[")"],
    },
    {
        date:'17 Sunday',
        name:"വിശ്വാസപരിശീലനം (16)",
        content:[" UNIT VISIT- 7"],
    },
    {
        date:' 24 Sunday ',
        name:'വിശ്വാസപരിശീലനം (17) ',
        content:["Principals' Conference അടുത്ത ഞായർ"," UNIT VISIT- 8"],
    },
    {
        date:'20 Sunday',
        name:'വിശ്വാസപരിശീലനം (12) ',
        content:["◆ UNIT VISIT- 3"],
    },
    {
        date:'27 Wednesday',
        name:'* വർക്ക്ഷോപ്പ് (ലേ ലീഡർഷിപ്പ് ഇൻസ്റ്റിറ്റ്യൂട്ട്)',
        content:[""],
    },
    {
        date:'29 - Oct. 01 Friday-Sunday',
        name:' ♦ CHRISFORM I - BATCH (06) ',
        content:[],
    },

],   


},
{ 
    month:'October 2023',
    events:[
    {
    date:'01 Sunday',
    name:' വിശ്വാസപരിശീലനം (18)',
    content:["സാമൂഹിക പ്രതിബദ്ധതാദിനം ( ലീഡർഷിപ്പ് ഇൻസ്റ്റിറ്റ്യൂട്ട്)"],
    },
    {
        date:'02 Monday',
        name:'വിശ്വാസപരിശീലനം (19)',
        content:["♦ Half Yearly Examination. Stds. I, III, V, VII, IX, XI (2 pm- 4 pm"],
    },
    {   date:'06 Wednesday',
        name:'വിശ്വാസപരിശീലനം (20)',
       content:["♦ Half Yearly Examination.Stds. II, IV, VI, VIII, X, XII & ACC (2 pm - 4 pm)"],
    },
    {
        date:'20-22 Friday-Sunday',
        name:'♦ CHRISFORM I BATCH (7)',
        content:[],
    },
    {
        date:'22 Sunday',
        name:' വിശ്വാസപരിശീലനം (21) മിഷൻ ഞായർ',
        content:["എല്ലാ ക്ലാസ്സുകളിലെയും അർദ്ധവാർഷിക പരീക്ഷയുടെ മാർക്ക് ലിസ്റ്റ് സോഫ്റ്റ് വെയറിലേക്ക് upload ചെയ്യുന്നതിനുള്ള ആരംഭം"],
    },
    {
        date:'22-24 Sunday - Tuesday',
        name:'Teachers Retreat',
        content:["Examination for Absentees & Re-Examinations (in Units) എന്നിവയ്ക്കുള്ള വിദ്യാർത്ഥികളുടെ പേര് സോഫ്റ്റ് വെയറിൽ രജിസ്റ്റർ ചെയ്യുന്നതിനുള്ള അവസാന തിയ്യതി നവംബർ 14",]
    },
    {
        date:'23 Monday',
        name:'♦ Archdiocesan Level Arts Competition at DBCLC & St.Clare’s C.G.H.S.S. (ചിൽഡ്രൻസ് മിനിസ്ട്രി)  ',
        content:[""],
    },
    {
        date:'27-28 Friday-Sunday',
        name:'♦ CHRISFORM I BATCH (7)',
        content:[" UNIT VISIT- 7"],
    },
    {
        date:' 29 Sunday ',
        name:'വിശ്വാസപരിശീലനം (22) ',
        content:["♦ Saints’ Quiz Parish Level (ചിൽഡ്രൻസ് മിനിസ്ട്രി)",],
    },
    
], 
},
{  
    month:'November 2023',
    events:[
    {
    date:'03-05 Friday-Sunday',
    name:' ♦ CHRISFORM I - BATCH (9)',
    content:[""],
    },
    {
        date:'04 Saturday',
        name:' Feast of St. Charles Borromeo',
        content:["♦ വിശ്വാസപരിശീലകരുടെ ദിനാഘോഷം - അതിരൂപതയിൽ ആചരണം"],
    },
    {   date:'05 Sunday',
        name:'വിശ്വാസപരിശീലനം (23)',
       content:["♦ വിശ്വാസപരിശീലകരുടെ ദിനാഘോഷം (ഇടവക തലത്തിൽ)","♦ SMCC One day Camp - Std. XII (യൂണിറ്റുകളിൽ നിന്നും തെരഞ്ഞെടുക്കപ്പെട്ട വിദ്യാർത്ഥികൾക്ക്)"],
    },
    {
        date:'10-12 Friday-Sunday',
        name:'♦ CHRISFORM I BATCH (10)',
        content:[],
    },
    {
        date:'11 Saturday',
        name:' വർക്ക്ഷോപ്പ് (ലേ ലീഡർഷിപ്പ് ഇൻസ്റ്റിറ്റ്യൂട്ട്)',
        content:["എല്ലാ ക്ലാസ്സുകളിലെയും അർദ്ധവാർഷിക പരീക്ഷയുടെ മാർക്ക് ലിസ്റ്റ് സോഫ്റ്റ് വെയറിലേക്ക് upload ചെയ്യുന്നതിനുള്ള ആരംഭം"],
    },
    {
        date:'12 Sunday',
        name:'വിശ്വാസപരിശീലനം (24)',
        content:[""]
    },
    {
        date:'13 Monday',
        name:'♦ എല്ലാ ക്ലാസ്സുകളിലെയും അർദ്ധ വാർഷിക പരീക്ഷയുടെ മാർക്കിസ്റ്റ് സോഫ്റ്റ് വെയറിലേക്ക് upload ചെയ്യുന്നതി നുള്ള അവസാന തിയ്യതി.  ',
        content:["♦ Nov 05 ഉൾപ്പെടെയുള്ള ഞായറാഴ്ച്ചകളിലെ Attendance സോഫ്റ്റ് വെയറിലേക്ക് upload ചെയ്യുന്നതിനുള്ള അവസാന തിയതി.","♦ പുനഃപരീക്ഷക്കുള്ള (തോറ്റവർക്കും മുടങ്ങിയവർക്കും) വിദ്യാർത്ഥികളുടെ പേര് സോഫ്റ്റ് വെയറിൽ രജിസ്റ്റർ ചെയ്യു ന്നതിനുള്ള അവസാന തിയ്യതി."],
    },
    {
        date:'17-19 Friday-Sunday ',
        name:'♦  CHRISFORM I - BATCH (11)',
        content:[" "],
    },
    {
        date:' 19 Sunday ',
        name:'വിശ്വാസപരിശീലനം (25) ',
        content:["അർദ്ധവാർഷിക പരീക്ഷ മാറ്റിവച്ച യൂണിറ്റുകൾക്കും പുനഃ പരീക്ഷകളും (തോറ്റവർക്കും മുടങ്ങിയവർക്കും - യൂണിറ്റു alo8) 2 pm-4 pm",],
    },
    {
        date:' 24-26  ',
        name:'SMCC Std. XII ',
        content:["Selected Students’ Camp for three days",],
    },
    {
        date:' 26 Sunday ',
        name:'വിശ്വാസപരിശീലനം (26) ',
        content:["♦ മിശിഹായുടെ രാജത്വതിരുനാൾ","♦ എക്സൽ എക്സലൻസ് എക്സാം യൂണിറ്റുകളിൽ 3pm-4pm (എക്സൽ അക്കാദമി)"],
    },
    {
        date:'27 Monday ',
        name:'♦ മാറ്റിവച്ച പരീക്ഷകളുടെയും പുനഃപരീക്ഷകളുടെയും മാർക്ക് എൻട്രി സോഫ്റ്റ് വെയറിൽ ചെയ്യേണ്ട അവസാന ദിവസം ',
        content:[""],
    },
    
],   

},
{  
    month:'December 2023',
    events:[
    {
    date:'01-03 Friday-Sunday',
    name:' ♦ CHRISFORM I - BATCH (12)',
    content:[""],
    },
    {
        date:'03 Sunday',
        name:' വിശ്വാസപരിശീലനം (27)',
        content:["♦ Aptitude Examination Unit Level (3 pm - 4.30 pm)","♦  Saints’ Quiz Forane Level(ചിൽഡ്രൻസ് മിനിസ്ട്രി)"],
    },
    {   date:'08-10 Friday-Sunday',
        name:'♦ CHRISFORM  II BATCH (04)',
       content:[""],
    },
    {
        date:'10-12 Friday-Sunday',
        name:'♦ വിശ്വാസപരിശീലനം (28)',
        content:[" ♦CHRISFORM III & FRESHERS COURSE (GATHERING 03) - 9 am to 5 pm"],
    },
    {
        date:'15-17 Friday-Sunday',
        name:' ♦ CHRISFORM I - BATCH (13)',
        content:[""],
    },
    {
        date:'17 Sunday',
        name:'വിശ്വാസപരിശീലനം (29)',
        content:[""]
    },
    {
        date:'18 Monday',
        name:'♦ ആപ്റ്റിറ്റ്യൂഡ് യൂണിറ്റുതല പരീക്ഷയുടെ മാർക്കിസ്റ്റ് സോഫ്റ്റ് വെയറിലേക്ക് upload ചെയ്യുന്നതിനുള്ള അവസാന തിയ്യതി.  ',
        content:["♦ അതിരൂപതാതല പരീക്ഷയ്ക്കുള്ളവരുടെ പേര് സോഫ്റ്റ് വെയർ വഴി രജിസ്റ്റർ ചെയ്യാനുള്ള അവസാന തിയ്യതി.."],
    },
    {
        date:'24 Sunday ',
        name:'വിശ്വാസപരിശീലനം (30)',
        content:["♦  അധ്യാപകരുടെ ആപ്റ്റിറ്റ്യൂഡ് പരീക്ഷ അതിരൂപതാതല omlob (2:30 pm - 4:30 pm; DBCLC)"],
    },
    {
        date:' 25 Monday ',
        name:'ക്രിസ്തുമസ് ',
        content:["",],
    },
    {
        date:' 28-30 Thursday-Saturday  ',
        name:'♦ Youth Retreat - Std. XI, XII & ACC Students ',
        content:[],
    },
    {
        date:' 31 Sunday ',
        name:'വിശ്വാസപരിശീലനം (31) ',
        content:[""],
    },
   
    
],   

},
{  
    month:'January 2024',
    events:[
    {
    date:'02 Tuesday',
    name:' ♦ Saints Quiz - Archdiocesan Level - 2 pm to 4.30 pm DBCLC (ചിൽഡ്രൻസ് മിനിസ്ട്രി)',
    content:[""],
    },
    {
        date:'07 Sunday',
        name:' വിശ്വാസപരിശീലനം (31)',
        content:[""],
    },
    {   date:'13 Saturday',
        name:'എക്സൽ എക്സലൻസ് എക്സാംഏകദിന ക്യാമ്പ് (എക്സൽ അക്കാദമി',
       content:[""],
    },
    {
        date:'14 Sunday ',
        name:'♦ വിശ്വാസപരിശീലനം (32)',
        content:[""]
    },
    {
        date:'21 Sunday',
        name:' ♦  വിശ്വാസപരിശീലനം (33)',
        content:[""],
    },
    {
        date:'26 Friday',
        name:'Republic Day & Award Day',
        content:[""]
    },
    {
        date:'28 Sunday',
        name:'♦ വിശ്വാസപരിശീലനം (34) ',
        content:["♦ അതിരൂപതാതല മത്സരങ്ങളുടെ സമ്മാനദാനം - DBCLC (ചിൽഡ്രൻസ് മിനിസ്ട്രി)"],
    },
    {
        date:'29 Monday',
        name:'വിശ്വാസപരിശീലനം (30)',
        content:["XII, ACC ക്ലാസ്സുകളിലെ വിദ്യാർത്ഥികളുടെ പരീക്ഷ യോഗ്യത നിർണ്ണയിക്കുന്നതിനുവേണ്ടി, ജനുവരി 22 ഉൾപ്പെടെയുള്ള അവരുടെ ഹാജർ സോഫ്റ്റ് വെയറിൽ കൃത്യമായി ചേർക്കുന്നതിനുള്ള അവസാന തിയ്യതി."],
    },
    
   
],   

},
{  
    month:'February 2024',
    events:[
    {
    date:'04 Sunday',
    name:' വിശ്വാസപരിശീലനം (35)',
    content:["♦ Principals’ Conference"],
    },
    {
        date:'11 Sunday',
        name:' വിശ്വാസപരിശീലനം (36)',
        content:["♦ Annual Examinations - Std. X, XI, XII, ACC","♦ ACC, Std. XII Internal Assessment Marksupload സോഫ്റ്റ്‌വെയറിലേക്ക് ചെയ്യുന്നതിനുള്ള ആരംഭം"],
    },
    {   date:'12 Monday',
        name:('വിഭൂതി'),
       content:[""],
    },
    {
        date:'18 Sunday',
        name:'♦ വിശ്വാസപരിശീലനം (37)',
        content:["♦ Annual Examinations - Std. I, III, V, VII, IX"," Std. I, III, V, VII, IX, XI Internal Assessment Mark സോഫ്റ്റ്‌വെയറിലേക്ക് ചെയ്യുന്നതിനുള്ള ആരംഭം"],
    },
    {
        date:'25 Sunday',
        name:' ♦ വിശ്വാസപരിശീലനം (38)',
        content:["♦ Annual Examinations - Std. II, IV, VI, VII","♦ Std. II, IV, VI, VIII, X Internal Assessment Marks സോഫ്റ്റ്‌വെയറിലേക്ക് ചെയ്യുന്നതിനുള്ള ആരംഭം"," Std. XII & ACC Chief Examiners’ Meeting (03:00pm)"],
    },
    
],   

},
{  
    month:'March 2024',
    events:[
    {
    date:'03 Saturday',
    name:' ♦ Centralized Valuation of ACC & Std. XII at D.B.C.L.C (9:30 am-4:30 pm',
    content:[""],
    },
    {
        date:'04 Sunday',
        name:'  Centralized Valuation of ACC & Std. XII at D.B.C.L.C.(9:30 am-4:30 pm)',
        content:["♦ Unit Valuation Std. I to XI","വാർഷിക പരീക്ഷയുടെ 1 മുതൽ 11 വരെയുള്ള എല്ലാ ക്ലാസി ലേയും മാർക്ക് ലിസ്റ്റ്, സോഫ്റ്റ് വെയറിൽ Upload ചെയ്യൽ സ്കോളർഷിപ്പ് പരീക്ഷയ്ക്ക് നിർദ്ദേശിക്കുന്ന വിദ്യാർത്ഥിക ളുടെ Actual Registration സോഫ്റ്റ് വെയറിൽ ചെയ്യൽ എന്നിവയ്ക്കുള്ള ആരംഭം","♦ Absentees' Annual Examinations & Annual Re- Examinations(in Units) എന്നിവയ്ക്കുള്ള വിദ്യാർത്ഥികളുടെ പേര് സോഫ്റ്റ് വെയറിൽ രജിസ്റ്റർ ചെയ്യുന്നതിനുള്ള ആരംഭം","ACC, Std XII ക്ലാസ്സുകളിലെ ഇന്റേണൽ അസ്സസ്സ്മെന്റ് മാർക്കുകൾ സോഫ്റ്റ് വെയറിൽ ചേർക്കാനുള്ള അവസാന തിയ്യതി."],
    },
    {   date:'9 Saturday - Sunday',
        name:'♦ Centralized Valuation of ACC (Continuation) at DBCLC',
       content:["♦ First Holy Communion Teachers’ Formation Class (ചിൽഡ്രൻസ് മിനിസ്ട്രി) DBCLC"],
    },
    {
        date:'11 Monday',
        name:'',
        content:[" ♦ Absentees' Annual Examinations & Annual Re-Examina- tions (in Units) എന്നിവയ്ക്കുള്ള വിദ്യാർത്ഥികളുടെ പേര് സോഫ്റ്റ് വെയറിൽ രജിസ്റ്റർ ചെയ്യുന്നതിനുള്ള അവസാന തിയ്യതി."],
    },
    {
        date:'16 Saturday',
        name:' ',
        content:["♦ 1-11 ക്ലാസ്സുകൾക്ക് മാറ്റിവച്ച പരീക്ഷകളും പുനഃപരീക്ഷകളും യൂണിറ്റുകളിൽ"],
    },
    {
        date:'24 Sunday',
        name:'Palm Sunday',
        content:[""]
    },
    {
        date:'28 Thursday',
        name:'Maundy Thursday  ',
        content:[""],
    },
    {
        date:'29 Friday ',
        name:'Good Friday',
        content:[""],
    },
    {
        date:' 31 Sunday ',
        name:'Easter ',
        content:["",],
    },
    {
        date:' 25 Monday ',
        name:'♦മാറ്റിവച്ച പരീക്ഷകളുടെയും പുനഃപരീക്ഷകളുടെയും മാർക്ക് ലിസ്റ്റ് സോഫ്റ്റ് വെയറിൽ Upload ചെയ്യാനുള്ള അവസാന തിയ്യതി. ',
        content:[],
    },

    
],   

},
{  
    month:'April 2024',
    events:[
    {
    date:'13 Saturday',
    name:' ♦ ACC & Plus Two Result',
    content:[" ♦ ACC & Plus Two Re-Examination/Improvement Examination-ന് രജിസ്റ്റർ ചെയ്യാനുള്ള ആരംഭം"],
    },
    {
        date:'14 Sunday',
        name:' ',
        content:[""],
    },
    {   date:'19-21 Friday to Saturday',
        name:'Basic CTC (1))',
       content:[""],
    },
    {
        date:'27 Saturday',
        name:'♦ ACC & Plus Two Re-Examination/Improvement Examination DBCL',
        content:[""],
    },
    {
        date:'28 Sunday',
        name:' ',
        content:[""],
    },
],   

},
{  
    month:'May 2023',
    events:[
    {
    date:'05 Sunday',
    name:' Advanced CTC 9.30 am to 4pm DBCLC Class (Day 1)',
    content:[""],
    },
    {
        date:'12 Sunday',
        name:' Advanced CTC 9.30 am to 4pm DBCLC Class (Day 2',
        content:[""],
    },
    {   date:'19 Sunday',
        name:'♦ Angelos 2024 - Solemn Holy Communion Children’s Meet (ചിൽഡ്രൻസ് മിനിസ്ട്രി)',
       content:[" ♦ Principals’ Conference, DBCLC"],
    },
    {
        date:'26 Sunday',
        name:'വിശ്വാസ പരിശീലകരുടെ അർദ്ധദിന സെമിനാർ -ഫൊറോന തലം',
        content:[""],
    },
],   

},
];
