export const AdminiArray =[
    {  
    "patron":[{"name":"Mar Andrews Thazath",
    "title":"Archbishop Of Trichur" ,
    "img":'/assets/bishop/image1.jpg'} ],    
    },
    {"Official_Members":[
    {"name":"Mon.Jose Konnikara Vicar General",
    "title":"Chairman" ,
    "img":'/assets/dbclc-photos/jose_konnikara.jpg'},
    {"name":"Fr.Dr.Francis Aloor, Director",
    "title":"Executive Chairman" ,
    "img":'/assets/dbclc-photos/francis_aloor.jpg'},    
    ]   
    },
    {"Assisstant_Directors":[
    {"name":"Fr.Lijo Challissery",
    "title":"Assisstant Director" ,
    "img":'/assets/dbclc-photos/lijo_challisery.jpg'},
    {"name":"Fr.Sijo Muringathery",
    "title":"Assisstant Director" ,
    "img":'/assets/dbclc-photos/sijo_mringathery.jpg'},
    {"name":"Fr. Jixon Malokaran",
    "title":"Assisstant Director" ,
    "img":'/assets/dumyp.png'},
    ]},
    {"Office_Representatives":[
    {"name":"Sr.Elvina Mariya CHF",
    "title":"Office Representative" ,
    "img":'/assets/dumyp.png'},
    {"name":"Thomas",
    "title":"Office Representative" ,
    "img":'/assets/dumyp.png'},   
    ]},
    {"Forane_Promoter_Representatives":[
    {"name":"Fr.Poulson Pallathingal",
    "title":"Forane Promoter" ,
    "img":'/assets/dbclc-photos/paulson_palathingal.jpg'},
    {"name":"Fr.Seby Kanjirathingal",
    "title":"Forane Promoter" ,
    "img":'/assets/dbclc-photos/sebi_kanirathingal.jpg'},
    {"name":"Fr.Johnson Anthikadan",
    "title":"Forane Promoter" ,
    "img":'/assets/dbclc-photos/johnson_anthikadan.jpg'},
    {"name":"Fr. Prince Chiriyankandath",
    "title":"Forane Promoter" ,
    "img":'/assets/dbclc-photos/prince_chiriyankandath.jpg'},  
    ]},
    {"Religious_Representative": [
    {"name":"Sr.Helma FCC",
      "title":"Religious Representative" ,
      "img":'/assets/dumyp.png'}         
    ]},
       {"Office_Bearers": [
        {"name":"Sri George V K",
        "title":"Convener" ,
        "img":'/assets/dumyp.png',
        "place" :"Mattom"},
        {"name":"Sri Binoy C F",
        "title":"Jo.Convener" ,
        "img":'/assets/dumyp.png',
        "place" :"Nellikunnu"},
        {"name":"Mathew Koola ",
        "title":"President" ,
        "img":'/assets/dumyp.png',
        "place" :"Nehru Nagar"},
        {"name":"Joshy John Chungath",
        "title":"Vice President" ,
        "img":'/assets/dumyp.png',
        "place" :"Mariyapuram"},
        {"name":"Jessy Shaju",
        "title":"Secretary" ,
        "img":'/assets/dumyp.png',
        "place" :"Chemmanur"},
        {"name":"Xavier",
        "title":"Jo.Secretary" ,
        "img":'/assets/dumyp.png',
        "place" :"Palakkal"},       
      ]},
      {
      "Coordinators":[
        {"name":"Dr.George Leons",
        "title":"Family Catechism" ,
        "img":'/assets/dumyp.png',
        "place":"Pallikunnu"},
        {"name":"Anjali",
        "title":"Infant Catechism" ,
        "img":'/assets/dumyp.png',
        "place":"Arthat"},
        {"name":"Valsa Tom",
        "title":"Children Catechism" ,
        "img":'/assets/dumyp.png',
        "place":"Machad"},
        {"name":"Ajin George",
        "title":"Adolescent Catechism" ,
        "img":'/assets/dumyp.png',
        "place":"Arannattukara"},
        {"name":"Lijo Perumayan",
        "title":"Youth Catechism" ,
        "img":'/assets/dumyp.png',
        "place":"Thiroor"},
        {"name":"Dr.Ignatious Antony",
        "title":"Adults Catechism" ,
        "img":'/assets/dumyp.png',
        "place":"Lourde"},
        {"name":"Joseph T L",
        "title":"Elders Catechism" ,
        "img":'/assets/dumyp.png',
        "place":"Parannur"},
        {"name":"Sheeja",
        "title":"Catechumens Catechism" ,
        "img":'/assets/dumyp.png',
        "place":"Kaveed"},
        {"name":"Dr.Tony Joseph",
        "title":"Social Ministry" ,
        "img":'/assets/dumyp.png',
        "place":"Mariyapuram"},
        {"name":"Jaison",
        "title":"Talent Ministry" ,
        "img":'/assets/dumyp.png',
        "place":"Avanoor"},
        {"name":"Dr. Noby Chandy",
        "title":"Spiritual Ministry" ,
        "img":'/assets/dumyp.png',
        "place":"Basilica"},
        {"name":"Shijo V K",
        "title":"Intercession Ministry" ,
        "img":'/assets/dumyp.png',
        "place":"Thalakkotukara"},      
      ]},   
  ];