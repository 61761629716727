import React from "react";
import { Array } from "../comp/ActivityArray";
import '../css/Activity.css'
import { useLocation } from 'react-router-dom';
import ForaneUnitcard from "../custom components/ForaneUnitcard";
import {ForanemembersArray} from"../comp/ForanemembersArray";
import CustomImage from "../custom components/CustomImage";
function Forane(props) {
const location = useLocation()
  const from = location.state
  console.log(from);
  return (
    <div class="  aboutcontainer p-0">
    <div class=" boxabout p-2  d-flex align-items-center justify-content-center ">
    <div> 
     <p class=" fs-1  text-white  fw-bold text-center  " > {from.forane} FORANE </p></div>
    </div>
    <div class=" row m-0 p-3">
    { ForanemembersArray[0].Forane.map((item,index)=>(
       <div class=" col-sm-12 col-md-4 col-lg-4 d-flex align-items-center justify-content-center p-2"  key={index}>
       <CustomImage
        img={item.img}
        name={item.name}
        // title={item.title}
        phone="9176524265"
        height="300"
        width="250"
        />
       </div>  
     ))}
    <div class=" col-md-7  col-sm-12 col-lg-8 d-flex align-items-center justify-content-center p-2">
    </div>
  </div>
  <div class=" row  m-0 p-3">
    { from.unit.map((item,index)=>(
       <div class=" col-sm-12 col-md-3 col-lg-3 d-flex align-items-center justify-content-start p-2"  key={index}>
        <ForaneUnitcard unit={item}/>
       </div>  
     ))}
    <div class=" col-md-7  col-sm-12 col-lg-8 d-flex align-items-center justify-content-center p-2">
    </div>
   
      

  </div>
   </div>
  );
}
export default Forane;

