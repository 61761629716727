import React from 'react'
import '../css/notificationBlock.css'
//text-truncate-2
//border border-1 border-dark
 export const NotificationBox= (props)=>{
    return (
        <div class=" ncard p-2 bg-white rounded-3 mt-2 border border-1 border-dark shadow-sm">
        <h6 class="bg-light text-black d-block fw-bold "> {props.heading}</h6> 
        {/* <p class="fs-6 text-black  ">uploaded:{props.date}</p>    */}
         <p class="fs-6 text-black d-block "> {props.content} <a href={props.pdf} target="_blank"  
        //  rel="noopener" 
         rel="noreferrer"
         class="text-decoration-none text-primary" 
        //  download={props.pdf}
         > read more...</a></p>        
        </div>
    )
}

const NotificationBlock =()=>{
  return (<div class=" border border-1 border-Primary px-1">
     <div class="text-dark fs-5 text-center py-2 bg-white   ">
         Notifications
        </div>
     <div class=" nblock m-1 overflow-auto p-1  w-100 bg-white ">
         
         <NotificationBox
          heading="Catechism Press News : Mar andrews thazhath"
          content=" യേശുവിന്റെ സാക്ഷികളാകുക മാര് ആന്ഡ്രൂസ് താഴത്ത് വിശ്വാസപരിശീലനം യുവതലമുറയെ ബോധ്യമുള്ള ഹ്രസ്വസാക്ഷികളാക്കുന്ന തിനാണെന്ന് മാര് ആന്ഡ്രൂസ് താഴത്ത് മെത്രാപ്പോലീത്ത പ്രസ്താവിച്ചു"
        //   date="19.04.23"
              pdf="Catechism_Press_News.pdf"
         />
        
     </div>

  </div>
   
  )
}
export default NotificationBlock;
