import React from 'react'

export default function CustomImage(props) {
  return (
    <div class="p-2 d-flex flex-column justify-content-center align-items-center  ">   
      <img class="shadow-lg" src={props.img} alt="Slideshow" width={props.width} height={props.height} />
      <p class="fs-5  text-primary m-1 text-center">{props.name} </p>
      <p class="fs-6 text-primary  m-1 font-italic text-center">{props.phone} </p>      
      <p class="fs-6 text-black  m-1 font-italic text-center">{props.title} </p>    
    </div>
  )
}
