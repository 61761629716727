import React from "react";
import CarouselComponent from "../custom components/CarouselComponent";
import Repeatimage from "../custom components/Repeatimage";
import CustomCard from "../custom components/CustomCard";
import CoursesCard from "../custom components/CoursesCard";
import Courses from "./Courses";
import Registration from "./Registration";
import "../css/Home.css";
import NotificationBlock from "../custom components/NotificationBlock";
import Newsevents from "../custom components/Newsevents";
import CustomLinkBlock from "../custom components/CustomLinkBlock";
import { Link } from "react-router-dom";

export default function Home() {

  const list1 =  [
    {img:'/assets/bishop/Pope-Francis.jpg',
    name:'Pope Francis',
    title:'Pope'
  }, 
  {
    img:'/assets/bishop/alenchery.jpg',
    name:'Mar George Cardinal Alencherry',
    title:'Major Archbishop'
  }, 
    {
    img:'/assets/bishop/image1.jpg',
    name:'Mar Andrews Thazhath',
    title:'Archbishop'
  },
  {
    img:'/assets/bishop/image2.jpeg',
    name:'Mar Tony Neelankavil',
    title:'Aux. Bishop '

  }
    // '/assets/bishop/Pope-Francis.jpg',
    // '/assets/bishop/image2.jpeg',
    // Add more image URLs as needed
  ];
  
 
  const list2 = [
    {
      img:'/assets/personalites/jesus.jpg',
      name:'Jesus',
      title:''
  
    },
    {
      img:'/assets/personalites/Charles_Borromeo.jpg',
      name:'St.Charles Borromeo',
      title:''
  
    },
    {
      img:'/assets/personalites/mary.jpg',
      name:'St.Mary',
      title:''
  
    },
    {
      img:'/assets/personalites/St-Joseph.jpg',
      name:'St.Joseph',
      title:''
  
    },
    {
      img:'/assets/personalites/st_thomas1.png',
      name:'St.Thomas',
      title:''  
    },
  ];
  return (
    <div class="p-1 w-100 home-main">
      <div class="row p-0 m-0 w-100 my-5  d-flex justify-content-center align-items-center ">
        <div class=" col-sm-12 col-md-12 col-lg-3 p-3  d-flex  justify-content-center align-items-center">
          <Repeatimage images={list2} />
        </div>
        <div class=" col-sm-12 col-md-12 col-lg-6 p-3  d-flex  justify-content-center align-items-center">
          <CarouselComponent />
        </div>
        <div class=" col-sm-12 col-md-12 col-lg-3  p-3 d-flex  justify-content-center align-items-center">
          <Repeatimage images={list1} />
        </div>
      </div>
      <hr/>
      <div class="row p-2 m-0  w-100 my-5  ">
        <div class=" col-sm-12 col-md-12   col-lg-3 p-1 d-flex flex-wrap justify-content-center align-items-center">
          <CustomLinkBlock
            link="https://www.vatican.va/archive/ENG0015/_INDEX.HTM"
            name="Document -(CCC)"
            img="/assets/logos/doc12.png"
          />
          <Link to="/usefullinks" class="text-decoration-none">
          <CustomLinkBlock
            // link="/"
            name="Useful Links"
            img="/assets/logos/link2.png"
          />
          
          </Link>
          
          <CustomLinkBlock
            link="/"
            name=" Videos"
            img="/assets/logos/video.png"
          />
          <CustomLinkBlock
            link="/"
            name="Formation Programs"
            img="/assets/logos/formation-program.png"
          />
          <CustomLinkBlock
            link="/"
            name="Students Column"
            img="/assets/logos/students1.png"
          />
        </div>
        <div class=" col-sm-12 col-md-12 col-lg-6 p-2  d-flex  justify-content-center align-items-start p-3">
        <div class=" ">
                <p class="m-2 fs-1 fw-bold text-dark ">Our Mission</p>
                <p class="m-2 fs-5  text-primary ">
                  The Mission of the Archdiocesan Catechetical Centre is to
                  introduce and to conduct ongoing training programmes that help
                  the faithful to deepen and build up their faith in the person
                  of Jesus Christ.
                </p>
                {/* <p class="m-2 fs-5  text-dark ">
                  The centre conducts, therefore, various faith formation
                  programmes for Children and for youth (Ex: Sunday Catechism,
                  School Catechism, Moral Science Classes) and also for the
                  Catechists of the Archdiocese: Catechists' Training Course (C.
                  T. C.). One Day Seminars, Animation Courses, Aptitude
                  Examination, Service Award, Literary competitions.
                </p> */}
                <p  class=" fs-5  text-dark ">
           The centre conducts, therefore, various faith formation programmes for Children and for youth (Ex: Sunday Catechism, School Catechism, Moral Science Classes) and also for the Catechists of the Archdiocese: Catechists' 
           Training Course (C. T. C.). One Day Seminars, Animation Courses, Aptitude Examination, Service Award, Literary competitions, etc…, There are 231 Sunday Catechism Units in the Archdiocese.
            A total of about 70000 students and 5000 teachers are enrolled under Sunday catechism programme. More than one lakh students are doing the daily catechism and moral science classes in 181 schools within the Archdiocese of Trichur. 
         </p>
                {/* <a
                  class="btn btn-outline-primary btn-lg text-decoration-none  "
                  href="/">
                  Join Us
                </a> */}
              </div>        
        </div>
        <div class=" col-sm-12 col-md-12 col-lg-3  p-2 d-flex flex-wrap justify-content-center align-items-start">
          <CustomLinkBlock
            link="/"
            name="Teachers Column"
            img="/assets/logos/teachers1.png"
          />
          <CustomLinkBlock
            link="/"
            name="Training Programs"
            img="/assets/logos/training.png"
          />
          <CustomLinkBlock
            link="/"
            name="loream ipsum"
            img="/assets/logos/formation-program.png"
          />
          <CustomLinkBlock
            link="/"
            name="loream ipsum"
            img="/assets/logos/video.png"
          />
          <CustomLinkBlock
            link="/"
            name="loream ipsum"
            img="/assets/logos/doc12.png"
          />
        </div>
      </div>
      <hr/>
      <div class="p-2 mt-5 " id="containerinfo">
        <div
          class="p-3 "
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1500">
          <div class=" row w-100">
            <div class=" col-sm-12 col-md-12 col-lg-5   d-flex  justify-content-center align-items-center">
              <img
                src="/assets/catisism/rosary.jpg"
                class=" img img-fluid w-100  h-100"
                alt="test image"
                id="aboutimg"
              />
            </div>
            <div class=" col-sm-12 col-md-12 col-lg-4 p-1 d-flex  justify-content-center align-items-center">
              {/* <div class="me-3 ">
                <p class=" fs-1 fw-bold text-dark ">Our Mission</p>
                <p class=" fs-5  text-primary ">
                  The Mission of the Archdiocesan Catechetical Centre is to
                  introduce and to conduct ongoing training programmes that help
                  the faithful to deepen and build up their faith in the person
                  of Jesus Christ.
                </p>
                <p class=" fs-5  text-dark ">
                  The centre conducts, therefore, various faith formation
                  programmes for Children and for youth (Ex: Sunday Catechism,
                  School Catechism, Moral Science Classes) and also for the
                  Catechists of the Archdiocese: Catechists' Training Course (C.
                  T. C.). One Day Seminars, Animation Courses, Aptitude
                  Examination, Service Award, Literary competitions.
                </p>
                <a
                  class="btn btn-outline-primary btn-lg text-decoration-none  "
                  href="/">
                  Join Us{" "}
                </a>
              </div> */}
               <Newsevents />
            </div>
            <div class=" col-sm-12 col-md-12 col-lg-3  d-flex  justify-content-center ">
              <NotificationBlock />
            </div>
            {/* <button class=" btn btn-primary float-xl-start">Float start on viewports sized XL (extra-large) or wider</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
