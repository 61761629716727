import React,{useState} from 'react'

export default function Login() {
     
  const[form,setForm]=useState({
    username: '',
    password: '',
    
  });
  // const handleChange = (e) => {
  //   setForm({ ...form, [e.target.name]: e.target.value });
  // }
 

  const submitButton = (e) => {
    e.preventDefault();
    console.log(form);
    resetButton()
  }
  const resetButton = () => {
    setForm({
      username: '',
      password: '',
   
    });
  }

  return (
    <div class=" p-0">
         <div class=" boxabout p-2  d-flex align-items-center justify-content-center ">
        <div>
          <p class=" fs-1  text-white  fw-bold text-center  ">Staff Login</p>
        </div>
        </div>
          <div class="p-0 m-0 row vh-100  bg-white d-flex  justify-content-center "> 
          <div class=" p-0 m-0 col col-lg-4 col-md-6 col-sm-12 mt-5 ">
            <div class=" p-3 m-3 rounded-4 shadow-lg ">
                <div>
                <p  class=" fs-5 text-center text-primary ">Cathechism Staff Login </p>
                </div>
            <form class="row g-4  p-0  row-lg-4 mt-4"  role="form"  >
<div class="col-md-12 col-lg-12">
  <input type="text" class="form-control border-black rounded-5 reg"  name="name" placeholder='Username' value={form.username} onChange={(e)=> setForm({...form,username:e.target.value})}  required/>  
</div>
<div class="col-md-12 col-lg-12">
  <input type="text" class="form-control border-black rounded-5 reg"  name="phone" placeholder='Password' value={form.password} onChange={(e)=> setForm({...form,password:e.target.value})}  required/>  
</div>
<div class="col-12 d-flex justify-content-center  ">
  <button class="btn btn-outline-primary btn-lg " type="submit" onClick={submitButton} >Login</button> 
</div>
     </form> 
            </div>
          </div>      
        </div>   
        </div>
  )
}
