export const Imagearray=[
"/assets/catisism/_K1A0116.JPG",
"/assets/catisism/_K1A2190.JPG",
"/assets/catisism/_K1A2244.JPG",
"/assets/catisism/_K1A2251.JPG",
"/assets/catisism/_K1A2268.JPG",
"/assets/catisism/_K1A2270.JPG",
"/assets/catisism/_K1A9320.JPG",
"/assets/catisism/_K1A9323.JPG",
"/assets/catisism/_K1A9326.JPG",
"/assets/catisism/_K1A9326.JPG",
"/assets/catisism/Newfolder/KA1.jpeg",
"/assets/catisism/Newfolder/KA2.jpeg",
"/assets/catisism/Newfolder/KA3.jpeg",
"/assets/catisism/Newfolder/KA4.jpeg",
"/assets/catisism/Newfolder/KA5.jpeg",
"/assets/catisism/Newfolder/KA6.jpeg",
"/assets/catisism/Newfolder/KA7.jpeg",
"/assets/catisism/Newfolder/KA8.jpeg",
"/assets/catisism/Newfolder/KA9.jpeg",
"/assets/catisism/Newfolder/KA10.jpeg",
"/assets/catisism/Newfolder/KA11.jpeg",
];