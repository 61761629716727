import React, { useState } from 'react';

const Fab = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  return (
    <div class=" d-flex alignitems-center justify-content-end p-3  fixed-bottom">
      {isVisible && (
        <button onClick={scrollToTop} class="fab btn btn-primary rounded-5">
          <i class="  fa-solid fa-arrow-up text-white "></i>
        </button>
      )}
    </div>
  );
};

export default Fab;
