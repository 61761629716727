import React from 'react'
import CustomLinkBlock from '../custom components/CustomLinkBlock'
import { LinksArray } from '../comp/linksArray'
import "../css/Usefullink.css"
export default function UsefulLinks() {
  return (
    <div class="header p-0">
    <div class=" boxabout p-2  d-flex align-items-center justify-content-center ">
    <div> 
    <p class=" fs-1  text-white  fw-bold text-center  ">Useful Links</p></div>
    </div>
    <div class="row p-2 m-0  w-100  vh-100  d-flex align-items-center justify-content-center">
    <div class="  col-sm-12 col-md-12 col-lg-6 " >
      {LinksArray.map((item,index)=>(
         <div class="usefullink  m-1 border border-1 border-dark">
      <div class=" p-2  m-1 ">
       <i class="  fa-solid fa-link fa-2 fw-bold text-black self-align-end"></i>
       <a href={item.link} class=" fs-6 text-black text-decoration-none">  {item.name}</a>   
       </div>

         </div>
      
      ))}
       </div> 
       </div>   
    </div>
  )
}
