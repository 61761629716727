import react from "react";
import { Link } from "react-router-dom";
import "../css/navbar.css";
import { ForaneArray } from "../comp/ForaneArray"
import { QbArray } from "../comp/QuestionbankArray";
function NavbarTop() {
  return (
    // fixed-top       for making scrollbar fixed at the top
    // sticky-top       for sticky component at tthe top
    <nav
      class="navbar navbar-expand-lg w-100  shadow-lg  p-0 m-0  justify-content-center align-items-center "
      id="navbar">
      <div class="row m-0 p-0 w-100">
        <div class="col-lg-12 logo-container d-flex w-100  align-items-center  justify-content-between ">
          <div class=" col-lg-6 col-md-12 col-sm-6 d-flex w-100  align-items-center  justify-content-between  ">
            <div class=" d-flex align-items-center">
              <Link class="navbar-brand  fs-2 " to="/">
                <img
                  src="/assets/logos/cathecathecial/logo-1.png"
                  alt="university"
                  width="120"
                  height="120"
                />
              </Link>
              <p class=" logo-text  ">
                D.B.C.L.C <br /> Archdiocesan Catechetical Centre
              </p>
            </div>
            <span class=" ms-5 p-1 ">
              <span
                class="navbar-toggler mr-auto "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="true"
                aria-label="Toggle navigation">
                  <i class="fa-solid fa-bars text-white "></i>
                {/* <span class="navbar-toggler-icon rounded"></span> */}
              </span>
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
}
export default NavbarTop;

export function NavbarBottom() {
  return (
    // fixed-top       for making scrollbar fixed at the top
    // sticky-top       for sticky component at tthe top
    <nav
      class="navbar navbar-expand-lg w-100 sticky-top shadow-lg  p-0 m-0 bg-white  justify-content-center align-items-center "
      id="navbar">
      <div class="row m-0 p-0 w-100">
        <div class="collapse navbar-collapse sticky-top col-lg-12 col-md-12 col-sm-12 justify-content-center "
          id="navbarNav">
          <ul class="navbar-nav d-flex flex-wrap  ">
            <li class="nav-item  p-1  ">
              <Link class="nav-link active" to="/">
                HOME
              </Link>
            </li>
            <li class="nav-item  p-1 ">
              <a href="/" class="nav-link active   ">
                HISTORY
              </a>
            </li>
            <li class="nav-item  p-1">
              {/* <Link class="nav-link active   fs-5 " to="/contact">Contact</Link>  */}
              <Link class="nav-link active" to="/administration">
              ADMINISTRATION
              </Link>
              {/* <a href="/" class="nav-link active ">
                ADMINISTRATION
              </a> */}
            </li>
            <li class="nav-item  p-1 ">
              {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link>   */}
              {/* <a href="/" class="nav-link active ">
                FORANE
              </a> */}
    <div class="dropdown">
  <a class=" nav-link active dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    FORANE
  </a>
  <ul class="dropdown-menu">
  { ForaneArray.map((item,index)=>(
    <li key={index}>
     < Link class=" dropdown-item " to={"/"+item.forane} state={item} >{item.forane}  
  </Link></li>
))
}
    {/* <li><a class="dropdown-item" href="#">Action</a></li>
    <li><a class="dropdown-item" href="#">Another action</a></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li> */}
  </ul>
</div>
{/* 
{ ForaneArray.map((item,index)=>(
  <Route path={"/"+item.toLowerCase()} key={index} element={<Forane/>}/> 
))
} */}
            </li>
            <li class="nav-item   p-1">
              {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link>  */}
              <Link class="nav-link active   " to="/activities">
              ACTIVITIES
              </Link>
              {/* <a href="/" class="nav-link active ">
                ACTIVITIES
              </a> */}
            </li>
            <li class="nav-item  p-1">
              {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link>  */}
              <div class="dropdown">
  <a class="nav-link active dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
  SUNDAY CATHECHISM

  </a>
  <ul class="dropdown-menu">
    {/* <li>
     < Link class=" dropdown-item " to={"/"+item.menuitem} state={item} >{item.menuitem}  
  </Link>
  </li> */}
  <li><a class="dropdown-item" href="/staff_login">Staff Login</a></li>
  <li><a class="dropdown-item" href="/">Exam Results</a></li>
  {/* <li><a class="dropdown-item" href="#">Another action</a></li> */}
    {/* <li><a class="dropdown-item" href="#">Action</a></li>
    <li><a class="dropdown-item" href="#">Another action</a></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li> */}
  </ul>
           </div>
{/*               
              <a href="/" class="nav-link active ">
                SUNDAY CATHECHISM
              </a> */}
            </li>
            <li class="nav-item  p-1">
              {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link>  */}
              <a href="/" class="nav-link active ">
                SCHOOL CATHECHISM
              </a>
            </li>
            <li class="nav-item  p-1">
              {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link>  */}
              <Link class="nav-link active" to="/gallery">
              GALLERY
              </Link>
              {/* <a href="/" class="nav-link active  ">
                GALLERY
              </a> */}
            </li>
            <li class="nav-item  p-1">
              {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link>  */}
              {/* <a href="/" class="nav-link active  ">
                NEWS & EVENTS
              </a> */}
              <Link class="nav-link active" to="/events">
              NEWS & EVENTS
              </Link>
            </li>
            <li class="nav-item  p-1">
            <div class="dropdown">
  <a class="nav-link active dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
  QUESTION BANK
  </a>
  <ul class="dropdown-menu">
  { QbArray.map((item,index)=>(
    <li key={index}>
     < Link class=" dropdown-item " to={"/"+item.menuitem} state={item} >{item.menuitem}  
  </Link></li>
))
}
    {/* <li><a class="dropdown-item" href="#">Action</a></li>
    <li><a class="dropdown-item" href="#">Another action</a></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li> */}
  </ul>
           </div>
              {/* <a href="/" class="nav-link active  ">
                QUESTION BANK
              </a> */}
            </li>
            <li class="nav-item  p-1">
              <Link class="nav-link active" to="/contact">
                CONTACT
              </Link>
              {/* <a href="/" class="nav-link active   fs-5 ">ADMINISTRATION</a> */}
            </li>
            {/* <li class="nav-item dropdown">
 <a class="nav-link  active fs-5  dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
   Coach Excel Educations
 </a>
 <ul class="dropdown-menu bg-light w-100 " aria-labelledby="navbarDropdown">
   <li><a class="dropdown-item fs-6 "  href="/">Education</a></li>
   <li><a class="dropdown-item fs-6 " href="/">Promotion</a></li>
   
   <li><a class="dropdown-item fs-6 " href="/">Job</a></li>
 </ul>
</li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
