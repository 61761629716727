export const ForanemembersArray =[
    {"Forane":[
    {"name":"Fr.Poulson Pallathingal",
    "title":"Forane Promoter" ,
    "img":'/assets/dumyp.png'},
    {"name":"Fr.Seby Kanjirathingal",
    "title":"Forane Promoter" ,
    "img":'/assets/dumyp.png'},
    {"name":"Fr.Johnson Anthikadan",
    "title":"Forane Promoter" ,
    "img":'/assets/dumyp.png'},
    {"name":"Fr.Johnson Anthikadan",
    "title":"Forane Promoter" ,
    "img":'/assets/dumyp.png'},
    {"name":"Fr.Johnson Anthikadan",
    "title":"Forane Promoter" ,
    "img":'/assets/dumyp.png'},
    {"name":"Fr. Prince Chiriyankandath",
    "title":"Forane Promoter" ,
    "img":'/assets/dumyp.png'},  
    ]},
  ];