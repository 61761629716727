
// import './App.css';
import Home from "./components/view/Home";
import NavbarTop,{NavbarBottom} from "./components/view/Navbar";
 import Footer from "./components/view/Footer";
 import Contact from "./components/view/Contact";
 import Registration from "./components/view/Registration";
 import CustomCard from "./components/custom components/CustomCard";
 import Courses from "./components/view/Courses";
import {BrowserRouter as Router,Route,Routes } from 'react-router-dom'; 
import About from "./components/view/About";
import Activity from'./components/view/Activity';
import { ForaneArray } from "./components/comp/ForaneArray";
import { QbArray } from "./components/comp/QuestionbankArray";
import Forane from "./components/view/Forane";
import Gallery from "./components/view/Gallery";
import Events from "./components/view/Events"
import Administration from "./components/view/Administration";
import QuestionBank from "./components/view/QuestionBank";
import UsefulLinks from "./components/view/UsefulLinks";
import Login from "./components/view/Login";
function App() {
  return (
    <Router>

<NavbarTop/>
<NavbarBottom/>
<Routes>
<Route path="/" element={<Home />}/>
<Route path="/about" element={<About />}/> 
<Route path="/contact" element={<Contact/>}/> 
<Route path="/activities" element={<Activity/>}/> 
{ ForaneArray.map((item,index)=>(
  <Route path={"/"+item.forane} key={index} element={<Forane/>}/> 
))
}
{ QbArray.map((item,index)=>(
  <Route path={"/"+item.menuitem} key={index} element={<QuestionBank/>}/> 
))
}
<Route path="/administration" element={<Administration/>}/> 
<Route path="/gallery" element={<Gallery/>}/> 
<Route path="/events" element={<Events/>}/> 
<Route path="/usefullinks" element={<UsefulLinks/>}/> 
<Route path="/staff_login" element={<Login/>}/> 


</Routes> 
{/* <Courses/> */}

<Footer/>
</Router> 
    
  );
}

export default App;
