import React from "react";
import "../css/linkBlock.css";
export default function CustomLinkBlock(props) {
  //  height="100%" width="100%"
  // width="350" height="150"
  return (
  
       <a href={props.link} class=" active   link-blok text-decoration-none rounded-3 p-1 m-1 ">
      <div class="card   card-main rounded-3 border border-1 border-dark p-2">
        <div class="card-body card-body-main p-2 rounded-3 ">
          <div class="row  p-0 m-0 ">
            <div class="col-4 d-flex align-items-center justify-content-center p-1 ">
              <img src={props.img} class="img-fluid rounded-3  " alt="link"  
              // height="80" width="80"
              />
            </div>
            <div class="col-8 px-2">
              <div class=" d-flex align-items-center justify-content-end h-25 ">
                <i class="  fa-solid fa-link fa-2 fw-bold text-black self-align-end"></i>
              </div>
              <div class=" d-flex align-items-center justify-content-center  h-75 w-100">
                <h5 class="card-title text-center text-decoration-none text-black fw-bold"> {props.name}</h5>
              </div>
              {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
            </div>
          </div>

          {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
        </div>
      </div>
    </a>
    
   
  );
}
