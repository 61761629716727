import React from 'react'
import '../css/aboutUs.css';
export default function About() {
  return (
    <div class="  aboutcontainer p-0">

<div class=" boxabout p-2  d-flex align-items-center justify-content-center ">
<div> <p class=" fs-1  text-white  fw-bold text-center  "   >About Us</p></div>
</div>
<div  class="  p-2 my-5 bg-white" id="about1">    
         <div class="p-3 "  data-aos="fade-up"  data-aos-easing="ease-out-cubic"
     data-aos-duration="1500">
      <div class=" row">
       
        <div class=" col-sm-12 col-md-12 col-lg-6 p-3  d-flex  justify-content-center align-items-center" >
          <div class="me-3"> 
          <p  class=" fs-1 fw-bold  text-dark ">A Few Words About<br/>Catechetical Centre
         </p>      
          <p  class=" fs-5  text-dark ">The Mission of the Archdiocesan Catechetical Centre is to introduce and to conduct ongoing training programmes that help the faithful to deepen and build up their faith in the person of Jesus Christ.
           The centre conducts, therefore, various faith formation programmes for Children and for youth (Ex: Sunday Catechism, School Catechism, Moral Science Classes) and also for the Catechists of the Archdiocese: Catechists' 
           Training Course (C. T. C.). One Day Seminars, Animation Courses, Aptitude Examination, Service Award, Literary competitions, etc…, There are 231 Sunday Catechism Units in the Archdiocese.
            A total of about 70000 students and 5000 teachers are enrolled under Sunday catechism programme. More than one lakh students are doing the daily catechism and moral science classes in 181 schools within the Archdiocese of Trichur. 
         </p>
         </div>
        </div>
        <div class=" col-sm-12 col-md-12 col-lg-6 d-flex  justify-content-center align-items-center" >
          
          <img src="/assets/catisism/cate.jpg" class=" img w-100 h-100" alt="test image" id="aboutimg" />   
                   
        </div>
      </div>
    </div>    
  </div>
  {/* <div  class="  p-2 my-5 bg-white " id="about2 ">    
         <div class="p-3 "  data-aos="fade-up"  data-aos-easing="ease-out-cubic"
     data-aos-duration="1500">
      <div class=" row">     
        <div class=" col-sm-12 col-md-12 col-lg-6 " >
        <div class="me-3">  
        <p  class=" fs-4 fw-bold  text-dark ">
        How We Help
         </p>     
          <p  class=" fs-5  text-dark ">
          Excel Academy, as an institute under the management of the Archdiocese of Thrissur, is always in contact with urban and rural parishes in and around the diocese. The parish priest can suggest the deserving candidates who need the financial help for their education.
         </p>
         <p  class=" fs-5  text-dark ">
         Full and partial scholarships are provided for the studies as their tuition fees. Today more than seven hundred deserving students are studying with scholarships. The students or parents would take the pledge that they would help a poor student when they are able to do in future.
         </p>
         </div>
           <img src="/assets/dumy.png" class=" img w-100 h-100" alt="test image" id="aboutimg" />    
                   
        </div>
        <div class=" col-sm-12 col-md-12 col-lg-6 p-3 " >
          <div class="me-3">  
          <p  class=" fs-4  fw-bold text-dark "> How You Can Help        
         </p>     
          <p  class=" fs-5  text-dark ">You can financially help a student by sponsoring them with full or partial contributions. Each penny you spend is a contribution to building a better society through education.         
         </p>
         <div>
         <a class="btn btn-outline-primary btn-sm text-decoration-none  "href="/">Sponcer a child </a> 
         </div>
        </div>
      </div>
    </div>    
  </div>
  <div  class="  p-2 bg-white" id="about3">    
         <div class="p-3 my-5 "  data-aos="fade-up"  data-aos-easing="ease-out-cubic"
     data-aos-duration="1500">
      <div class=" row g-4">
        <div class=" content col-sm-12 col-md-12 col-lg-4 " >
          
          <img src="/assets/images/slide-2.jpg" class=" img w-100 h-35 img-about" alt="test image" id="aboutimg" /> 
          <div class="m-3">  
          <p  class=" fs-4  text-dark ">
          Break Poverty
         </p>     
          <p  class=" hidden fs-6  text-dark ">
          We identify capable students in poor backgrounds from every parish in Thrissur and help them rise up
         </p>
         </div>
                   
 
      </div>
      <div class="  content col-sm-12 col-md-12 col-lg-4 " >
          
          <img src="/assets/images/slide-1.jpg" class=" img w-100 h-35 img-about " alt="test image" id="aboutimg"/>
          <div class="m-3">    
          <p  class=" fs-4  text-dark ">
          Better Society 
         </p>    
          <p  class=" hidden fs-6  text-dark ">
          Better Society
          Educated students are the building blocks of our society and with your help, we can make it happen 
         </p>
         </div>
                   
 
      </div>
      <div class=" content col-sm-12 col-md-12 col-lg-4 " >
          
          <img src="/assets/images/safe-future.png" class=" img w-100 h-35 img-about " alt="test image" id="aboutimg" /> 
          <div class="m-3">  
          <p  class=" fs-4  text-dark ">
          Secure Future
         </p>      
          <p  class=" hidden fs-6  text-dark ">
           We can break the cycle of poverty through education and secure the future of our society
         </p>
         </div>
                   
 
      </div>
    </div>    
  </div>

</div>
    </div> */}
    </div>
  )
}
