import React, { useState, useEffect } from 'react';

const AutoImageChange = ({ imgList }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Calculate the next image index
      const nextImageIndex = (currentImageIndex + 1) % imgList.length;
      setCurrentImageIndex(nextImageIndex);
    }, 5000); // Change image every 5 seconds (adjust as needed)

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [currentImageIndex, imgList]);

  return <>
    <img src={imgList[currentImageIndex].img} alt="Slideshow" width="250" height="300" />
    <p class="fs-6 fw-bold text-black m-1 text-center">{imgList[currentImageIndex].name} </p>
    <p class="fs-6 text-primary  m-1 font-italic text-center">{imgList[currentImageIndex].title} </p>

  </>
};

const RepeatImage = (props) => {
  // Define your list of image URLs
  // const images = [
  //   '/assets/bishop/image1.jpg',
  //   '/assets/bishop/Pope-Francis.jpg',
  //   '/assets/bishop/image2.jpeg',
  //   // Add more image URLs as needed
  // ];

  return (
    <div className="p-1 shadow-md border border-3 border-dark ">
      {props.images.length !== 0 ? <AutoImageChange imgList={props.images} /> : null}
    </div>
  );
};

export default RepeatImage;
