import React from "react";
import { Array } from "../comp/ActivityArray";
import '../css/Activity.css'
function Activity() {
  return (
    <div class=" p-0">

    <div class=" boxabout p-2  d-flex align-items-center justify-content-center ">
    <div> 
        <p class=" fs-1  text-white  fw-bold text-center  "   >Activities</p></div>
    </div>
    <div class="header">
{Array.map((item, index) => (
  <>
    <div class="row g-2 py-2 bg-light p-0 m-0" key={index}>
      <div class="col-lg-4 col-md-12 col-sm-12 px-3 d-flex">
        <p class="heading  m-3 ">{item.heading}</p>
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12 px-3 d-flex flex-row align-items-center">
        <div class="w-100">
          {item.content.map((item, index) => (
            <p class="content mx-3 " key={index}>
              {item}
            </p>
          ))}
        </div>
      </div>
    </div>
    <hr />
  </>
))}
</div>
  
   
        </div>
 
  );
}
export default Activity;

