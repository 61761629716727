import React from 'react'
import CustomImage from '../custom components/CustomImage'
import { AdminiArray } from '../comp/AdminArray'

export default function Administration() {
  
  return (
    <div class="p-0">
      <div class=" boxabout p-2  d-flex align-items-center justify-content-center ">
    <div> 
        <p class=" fs-1  text-white  fw-bold text-center ">Administration</p></div>
    </div>
      <div class="row p-0 m-0 d-flex  justify-content-center">
        {AdminiArray[0].patron.map((item,index)=>(<>
        <div></div>
        <div class="col-3 py-4" key={index}>
        <CustomImage
        img={item.img}
        name={item.name}
        title={item.title}
        height="300"
        width="250"
        />
        </div>
        </>))}

      </div>
      <div class="row p-0  m-0  d-flex  justify-content-center">
        {AdminiArray[1].Official_Members.map((item,index)=>(<>
        <div class="col-lg-3 col-md-3 col-sm-12 py-4" key={index}>
        <CustomImage
        img={item.img}
        name={item.name}
        title={item.title}
        height="250"
        width="200"
        />
        </div>
        </>))}

      </div>
      <div class="row p-0   m-0 d-flex  justify-content-center">
        {AdminiArray[2].Assisstant_Directors.map((item,index)=>(<>
        <div class="col-lg-3 col-md-3 col-sm-12  py-4" key={index}>
        <CustomImage
        img={item.img}
        name={item.name}
        title={item.title}
        height="250"
        width="200"
        />
        </div>
        </>))}

      </div>
      <div class="row p-0   m-0 d-flex  justify-content-center">
        {AdminiArray[3].Office_Representatives.map((item,index)=>(<>
        <div class="col-lg-3 col-md-3 col-sm-12  py-4" key={index}>
        <CustomImage
        img={item.img}
        name={item.name}
        title={item.title}
        height="250"
        width="200"
        />
        </div>
        </>))}
      </div>
      <div class="row p-0   m-0 d-flex  justify-content-center">
        {AdminiArray[4].Forane_Promoter_Representatives.map((item,index)=>(<>
        <div class="col-lg-3 col-md-3 col-sm-12  py-4" key={index}>
        <CustomImage
        img={item.img}
        name={item.name}
        title={item.title}
        height="250"
        width="200"
        />
        </div>
        </>))}

      </div>
      <div class="row p-0   m-0 d-flex  justify-content-center">
        {AdminiArray[5].Religious_Representative.map((item,index)=>(<>
        <div class="col-lg-3 col-md-3 col-sm-12  py-4" key={index}>
        <CustomImage
        img={item.img}
        name={item.name}
        title={item.title}
        height="250"
        width="200"
        />
        </div>
        </>))}

      </div>
      <div class="row p-0   m-0 d-flex  justify-content-center">
        {AdminiArray[6].Office_Bearers.map((item,index)=>(<>
        <div class="col-lg-3 col-md-3 col-sm-12  py-4" key={index}>
        <CustomImage
        img={item.img}
        name={item.name}
        title={item.title}
        height="250"
        width="200"
        />
        </div>
        </>))}

      </div>
      <div class="row p-0  m-0 d-flex  justify-content-center">
        {AdminiArray[7].Coordinators.map((item,index)=>(<>
        <div class="col-lg-3 col-md-3 col-sm-12  py-4" key={index}>
        <CustomImage
        img={item.img}
        name={item.name}
        title={item.title}
        height="250"
        width="200"
        />
        </div>
        </>))}

      </div>
       
    </div>
  )
}
