import React from 'react'
import { useLocation } from 'react-router-dom';
import ForaneUnitcard from "../custom components/ForaneUnitcard";
import {ForanemembersArray} from"../comp/ForanemembersArray";
export default function QuestionBank(props) {
    // const from = location.state
    // console.log(from);
  return (
    <div class="p-0">
    <div class=" boxabout p-2  d-flex align-items-center justify-content-center ">
  <div> 
      <p class=" fs-1  text-white  fw-bold text-center ">QuestionBank</p></div>
  </div>  
  <div class=" row  p-3">
    { ForanemembersArray[0].Forane.map((item,index)=>(
       <div class=" col-sm-12 col-md-3 col-lg-3 d-flex align-items-center justify-content-start p-2"  key={index}>
        <ForaneUnitcard unit={"loream ipsum"}/>
       </div>  
     ))}
    <div class=" col-md-7  col-sm-12 col-lg-8 d-flex align-items-center justify-content-center p-2">
    </div>
  </div>
  </div>
  )
}
