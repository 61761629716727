import React from 'react'
import '../css/newsevents.css'
//text-truncate-2
//border border-1 border-dark
 export const NotificationBox= (props)=>{
    return (
        <div class=" nacard p-2 bg-white rounded-3 mt-2 border border-1 border-dark shadow-sm">
       <h6 class="bg-light text-black fw-bold "> {props.heading}</h6> 
        {/* <p class="fs-6 text-black  ">uploaded:{props.date}</p>    */}
         <p class="fs-6 text-black "> {props.content} <a href={props.pdf} target="_blank"  
        //  rel="noopener" 
         rel="noreferrer"
         class="text-decoration-none text-primary" 
        //  download={props.pdf}
         > read more...</a></p>
        </div>
    )
}

const Newsevents =()=>{
  return (<div class=" border border-1 border-Primary px-1">
     <div class="text-dark fs-5 text-center py-2 bg-white   ">
         News & Events
        </div>
     <div class=" nblock m-1 overflow-auto p-1  w-100 bg-white ">      
         <NotificationBox
          heading="Catechism Press News : Mar andrews thazhath"
          content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        //   date="19.04.23"
            //   pdf="Catechism_Press_News.pdf"
         />
          <NotificationBox
          heading="Catechism Press News : Mar andrews thazhath"
          content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        //   date="19.04.23"
            //   pdf="Catechism_Press_News.pdf"
         />
         <NotificationBox
          heading="Catechism Press News : Mar andrews thazhath"
          content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        //   date="19.04.23"
            //   pdf="Catechism_Press_News.pdf"
         />
        
     </div>

  </div>
   
  )
}
export default Newsevents;
